import(/* webpackMode: "eager" */ "/vercel/path0/apps/my-mlh/public/img/logo/mlh.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/my-mlh/src/components/Avatar/Avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/my-mlh/src/components/Footer/footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationMenuLink","NavigationMenu","NavigationMenuList","NavigationMenuItem","NavigationMenuTrigger","NavigationMenuContent"] */ "/vercel/path0/apps/my-mlh/src/components/ui/navigation-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
