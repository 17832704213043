import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { UserIdentifier } from "@/services/mlh-core/gql/graphql";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function compareIdentifiers<
  T extends Pick<UserIdentifier, "primary" | "claimed">,
>(a: T, b: T) {
  if (a.primary && !b.primary) {
    return -1;
  }

  if (!a.primary && b.primary) {
    return 1;
  }

  if (a.claimed && !b.claimed) {
    return -1;
  }

  if (!a.claimed && b.claimed) {
    return 1;
  }

  return 0;
}

export type RequireKeys<T extends object, K extends keyof T> = Required<
  Pick<T, K>
> &
  Omit<T, K>;
